<template>
    <div>
        <div class="row">
            <div class="col-lg-12">
                <a-button class="btn btn-primary float-right" style="margin-left: 10px;" @click="openTask('')">Add Task</a-button>
                <a-button class="btn btn-dark float-right" @click="showSection = true">Add Section</a-button>
                <h3 class="nk-block-title page-title"><em class="icon ni ni-grid-plus"/> {{ project.name }}</h3>
            </div>
        </div>
        <div class="row" v-if="view == 'list'">
            <div class="col-lg-12">
                <projectList @completeTask="completeTask" @addSection="addSection" @addTask="addTask" @taskSelected="taskSelected" :project="project" :addNewTask="addNewTask" :taskTemplate="taskTemplate" />
            </div>
        </div>

        <!-- Drawer taskFrom -->
        <a-drawer :width="600" :mask="false" :maskClosable="false" placement="right" :visible="showTask" :closable="false" @close="closeTask">
            <taskForm :taskId="selectedTaskId" @close="closeTask" :projectId="project.id" />
        </a-drawer>

        <!-- Add Section modal -->
        <a-modal v-model="showSection" title="Add Section">
            <div class="row">
                <div class="col-lg-2">
                    <label class="form-label float-right">Title:</label>
                </div>
                <div class="col-lg-10">
                    <a-input class="invisibox" v-model="section.title" placeholder="Title"></a-input>
                </div>
            </div>
            <template slot="footer">
                <a-button class="btn btn-sm btn-success" @click="saveNewSection()">
                    <em class="icon ni ni-save"/> Save
                </a-button>
            </template>
        </a-modal>
    </div>
</template>

<script>
import projectList from '@/components/projects/list/index.vue'
import taskForm from '@/components/projects/taskForm.vue'

export default {
    components: { projectList, taskForm },
    data() {
        return {
            showTask: false,
            view: 'list',
            showSection: false,
            section: {},
            project: {},
            selectedTaskId: null,
            taskTemplate: null,
            addNewTask: {
                sectionId: null,
                show: false
            },
        }
    },
    created() {
        this.getView()
        this.getProject()
        this.getTemplates()
    },
    methods: {
        getView (){ this.$http.get('/ViewAccess/project')
        .then(() => { 
        })
        .catch(() => { 
        })},
        completeTask(taskId) {
            this.project.projectSections.forEach(section => {
                section.projectTasks.forEach((task,index) => {
                    if (task.id === taskId ){
                     this.$message.success(task.title + ' Complete')
                     section.projectTasks.splice(index,1)
                    }
                });
            });
        },
        addSection () {
            alert('Todo:- add a section using templates')
        },
        addTask (sectionId) {
            this.project.projectSections.forEach(section => {
                if (section.id  == sectionId){
                    this.addNewTask.sectionId = sectionId
                    alert('Todo:- add a task to section- ' + sectionId + ' this section using a template')
                    this.addNewTask.show = true
                }
            });
        },
        taskSelected (taskId){
             this.clearSelectedTasks(taskId)
             this.openTask(taskId)
        },
        openTask(taskId) {
            this.selectedTaskId = taskId
            this.minimizeTaskContainer()
            this.showTask = true
        },
        minimizeTaskContainer(){
            let el  = document.getElementById("taskContainer") 
            if (!this.showTask){
                let widthMinusDrawer =  el.offsetWidth - 400
                el.style.width = (widthMinusDrawer + 'px') 
            }
        },
         maximizeTaskContainer(){
            let el  = document.getElementById("taskContainer") 
            el.style.width = ('100%') 
        },
        closeTask() { 
            this.maximizeTaskContainer()
            this.showTask = false
        },
        getProject(){
            this.$http.get('/projects/Get_Project/' + this.$route.query.id)
            .then((response) => {
                this.project = response.data
            })
            .catch(() => {
                this.$message.error('There has been an error')
            })
        },
        saveNewSection() {
            this.section.projectId = this.$route.query.id
                this.$http.post('/projects/Add_Section', this.section)
                .then(() => {
                    this.$message.succes('Section Saved')
                    this.showSection = false
                    this.section = {}
                })
            .catch(() => {
                this.$message.error('There has been an error')
            })
            this.getProject()
        },
        // Special methods These want moving off to a class library
        clearSelectedTasks(id){
            this.project.projectSections.forEach(section => {
                section.projectTasks.forEach(task => {
                   if (task.id != id) {
                       task.selected = false
                   } else
                   {
                       task.selected = true
                   }
                });
            });
        },
        getTemplates(){
            this.$http.get('/projects/Get_Task/', this.taskId)
            .then((response) => { 
                this.taskTemplate = response.data
                this.taskTemplate.projectId = this.projectId
                // set SectionId etc etc
            })
            .catch(() => {
            this.$message.error('There has been an error')
            })
            // todo - get a task template and section template to use. then update templates
        }
        // end special methods. 
    }
}
</script>

<style scoped>
.form-label {
    margin-top: 5px;
}
</style>