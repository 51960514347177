<template>
    <div class="ticketMenu">
        <div v-on:click="saveTicket()" class="ticketMenuItemSave"><em class="float-left icon ni ni-save"></em></div>
        <div v-on:click="changeComponent('details')" class="ticketMenuItem ticketMenuDetails" style="width:65.5%; border-right:none;"><div class="ticketMenuName"> Task Details</div></div>
       
        <div class="float-right">
            <div v-on:click="changeComponent('notes')" style="border-left: 1px solid #000;" class="ticketMenuItem"><em class="float-left icon ni ni-edit"></em></div>
            <div v-on:click="changeComponent('attachments')" class="ticketMenuItem"><em class="float-left icon ni ni-clip"></em></div>
            <div v-on:click="changeComponent('users')" class="ticketMenuItem"><em class="float-left icon ni ni-users"></em></div>
            <div v-on:click="closeTask()" class="ticketMenuItem"><em class="float-left icon ni ni-cross"></em></div>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return {

        }
    },
    methods: {
        changeComponent (changeTo) {
            this.$emit('changeTabs',changeTo)
        },
        closeTask() {
            this.$emit('close')
        },
        saveTicket () {
         this.$emit('saveTask')
    },
    }
}
</script>

<style scoped>
.ticketMenuDetails {
    font-size: 16px;
    line-height: 17px;
    width:285px;
}
.ticketMenu {
    height: 31px;
    background-color: #fff;
    color: #000;
    width:100%;
    border-bottom: 1px solid #000;
}
.ticketMenu em {
    font-size: 20px;
}
.ticketMenu .ticketMenuItem{
    float: left;
    padding: 5px 10px;
    border-right: 1px solid #000;
    cursor:pointer; 
    height:30px !important; 
}
.ticketMenu .ticketMenuItem .ticketMenuName{
         margin-top: 3px;
}
.ticketMenu .ticketMenuItem:hover{
        background-color: rgb(232, 232, 232);
}

.ticketMenu .ticketMenuItemSave{
    background-color:#1ee0ac; 
    float: left;
    padding: 5px 10px;
    border-right: 1px solid #000;
    cursor:pointer; 
    height:30px !important;
}

.ticketMenu .ticketMenuItemSave:hover{
    background-color: #19be92;
}
</style>