<template>
    <div
        @click="selectTask()"
        @contextmenu="openTaskMenu"
        @mouseover="hoveredOver = true"
        @mouseleave="hoveredOver = false"
        v-bind:class="{ 'taskCellHovered' : hoveredOver, 'taskCellSelected' : task.selected }"
        class="taskRow">
        <div class="taskHandle "><em class="icon ni ni-more-v"></em><em style="margin-left: -9px;" class="icon ni ni-more-v"></em></div>
        <div class="taskCellContainer taskName"><div class="taskCell taskName"><input v-autowidth="{maxWidth: '960px', minWidth: '150px', comfortZone: 0}" @input="mixin_autoResize_resize"  class="taskRowInput" v-model="task.title"></div> </div>
        <div class="taskCellContainer"><div class="taskCell taskCellButtonFixed"><em class="icon ni ni-comments"></em></div></div>
        <div class="taskCellContainer"><div class="taskCell taskCellButtonFixed"><em v-on:click="completeTask()" class="icon ni ni-check-fill-c"></em></div></div>
        <div class="taskCellContainer"><div class="taskCell taskCellFixed">{{task.description}}</div></div>
        <div class="taskCellContainer"><div class="taskCell taskCellEnd"></div></div>
    </div>
</template>

<script>
export default {
    props: {
        task: { type: Object }
    },
    components: { },
    data() {
        return {
            hoveredOver: false,
            showTask: false,
        }
    },
    created() {
    },
    methods: {
        openTaskMenu(e){
            e.preventDefault();
            alert('Todo:- Open a menu at this location')
        },
        selectTask() { 
            this.selected = true
            this.$emit('taskSelected',this.task.id)
        },
        closeTask() { 
            this.showTask = false
        },
        completeTask(){
            this.$emit('completeTask', this.task.id)
        },
    }
}
</script>

<style>




</style>