<template>
    <div>
        <div class="row">
            <div class="col-lg-12">
                <taskMenu @saveTask="saveTask()" @changeTabs="changeTabs" @close="close()" />
            </div>
        </div>
        <div v-if="activeTabKey == 1" class="card-inner">
            <div class="row">
                <div class="col-lg-12">
                    <div class="row">
                        <div class="col-lg-3">
                            <label class="form-label float-right">Title:</label>
                        </div>
                        <div class="col-lg-7">
                            <a-input class="invisibox" v-model="task.title" placeholder="Title"></a-input>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-3">
                            <label class="form-label float-right">Details:</label>
                        </div>
                        <div class="col-lg-7">
                            <a-textarea class="invisibox" v-model="task.details" placeholder="Details"></a-textarea>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-3">
                            <label class="form-label float-right">Assigned To:</label>
                        </div>
                        <div class="col-lg-7">
                            <a-select class="invisibox" v-model="task.assignedToUserId" @change="assignTaskToUser" style="width:100%;" placeholder="Assigned To">
                                <a-select-option v-for="u in assignableUsers" :key="u.id">
                                    {{ u.firstName + ' ' + u.lastName}}
                                </a-select-option>
                            </a-select>
                        </div>
                    </div>
                    <!--TODO:- pull this through on the task/from the db we do not want to pull the entire section into this component. 
                    <div class="row">
                        <div class="col-lg-3">
                            <label class="form-label float-right">Section:</label>
                        </div>
                        <div class="col-lg-7">
                            <a-select class="invisibox" v-model="task.sectionId" style="width:100%;" placeholder="Section">
                                <a-select-option v-for="p in projectSections" :key="p.id">
                                    {{ p.title }}
                                </a-select-option>
                            </a-select>
                        </div>
                    </div> -->
                </div>
            </div>
            <div v-if="taskUsers">
                <hr class="preview-hr">
                <div class="row">
                    <div class="col-lg-12">
                        <h6 class="title">Currently Assigned To</h6>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12">
                        <vue-good-table
                            :rows="taskUsers"
                            :columns="userColumns"
                        >
                            <template slot="table-row" slot-scope="props">
                                <div v-if="props.column.field == 'startTime'">
                                    {{ $moment(props.row.startTime).format('H:mm A LL')}}
                                </div>
                                <div v-else-if="props.column.field == 'actions'">
                                    <a @click="stopAssignment(props.row)"><em class="icon ni ni-pause-fill" style="fontSize: 20px; color:red; margin-right: 10px;"/></a>
                                </div>
                            </template>
                        </vue-good-table>
                    </div>
                </div>
            </div>
            <hr class="preview-hr">
            
        </div>
        <div v-if="activeTabKey == 2" class="footer"> <!-- NOT HAPPY WITH THIS AT ALL but it is functional -->
            <div class="row">
                <div class="col-lg-12">
                    <notes
                    :id="taskId"
                    :entityName="'task'"
                    :getEndpoint="'/projects/Get_Task_Notes/' + taskId" 
                    :saveEndpoint="'/projects/Add_Task_Note/'"></notes>
                </div>
            </div>
        </div>
        <div v-if="activeTabKey == 3" class="card-inner">
            Attachments
        </div>
        <div v-if="activeTabKey == 4" class="card-inner">
            <div class="row">
                <div class="col-lg-12">
                    <h6>Users Time Spent</h6>
                </div>
                <div class="col-lg-12">
                    <taskUsersTimeSpent :taskId="taskId"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import taskMenu from '@/components/projects/taskMenu.vue'
import notes from '@/components/desk/tickets/ticketNotes.vue'
import taskUsersTimeSpent from '@/components/projects/taskUsersTimeSpent.vue'

export default {
    props: {
        //projectSections: {
        //    type: Array
        //},
        taskId: {
            type: String
        },
        projectId: {
            type: Number
        }
    },
    components: { taskMenu, notes, taskUsersTimeSpent },
    data() {
        return {
            users: [],
            task: {},
            activeTabKey: 1,
            taskUsers: null,
            assignableUsers: null,
            userColumns: [
                { label: 'User', field: 'assignedToUser', sortable: false },
                { label: 'Start Time', field: 'startTime', sortable: false },
                { label: 'Actions', field: 'actions', thClass: 'text-center', tdClass:'text-center', sortable: false },
            ]
        }
    },
    watch: {
        taskId: function () { 
           this.getTask()
        }
    },
    created(){
        this.getLists()
        this.getTask()
        this.getTaskAssignableUsers()
    },
    methods: {
        close() {
            this.$emit('close')
        },
        changeTabs(tabName) {
            if (tabName === 'details'){ this.activeTabKey = 1}
            if (tabName === 'notes'){ this.activeTabKey = 2}
            if (tabName === 'attachments'){ this.activeTabKey = 3 }
            if (tabName === 'users'){ this.activeTabKey = 4 }
        },
        getLists() {
            this.$http.get('/lists/Get_Users')
            .then((response) => {
                this.users = response.data
            })
            .catch(() => {
            this.$message.error('There has been an error')
            })
        },
        saveTask() {
            if ( this.task.createdDate != '0001-01-01T00:00:00' ){
                this.updateTask()
            } else {
                this.addTask()
            }
        },
        assignTaskToUser() {
            this.$http.post('/projects/Assign_TaskToUser', this.task)
            .then(() => {
                this.$message.success('Task Assigned')
                this.getTaskActivelyAssignedUsers()
                this.getTask()
            })
            .catch(() => {
            this.$message.error('There has been an error')
            })
        },
        stopAssignment(props){
            this.$http.post('/projects/StopAssign_TaskToUser', props)
            .then(() => {
                this.$message.success('Task Assigned')
                this.getTaskActivelyAssignedUsers()
                this.getTask()
            })
            .catch(() => {
            this.$message.error('There has been an error')
            })
        },
        addTask() {
            this.task.projectId = this.projectId
            this.$http.post('/projects/Add_Task', this.task)
            .then(() => {
                this.$message.success('Task Added')
            })
            .catch(() => {
            this.$message.error('There has been an error')
            })
        }, 
        getTask(){
            if (this.taskId != '') {
                this.$http.get('/projects/Get_Task/' + this.taskId)
                .then((response) => { 
                    this.task = response.data
                    this.getTaskActivelyAssignedUsers()
                })
                .catch(() => {
                this.$message.error('There has been an error')
                })
            } else {
                this.$http.get('/projects/Get_Task/', this.taskId)
                .then((response) => { 
                    this.task = response.data
                    this.task.projectId = this.projectId
                    // set SectionId etc etc
                })
                .catch(() => {
                this.$message.error('There has been an error')
                })
            }
        },
        getTaskActivelyAssignedUsers(){
            this.$http.get('/projects/Get_TaskActivelyAssignedUsers/' + this.taskId)
            .then((response) => { 
                this.taskUsers = response.data
            })
            .catch(() => {
            this.$message.error('There has been an error')
            })
        },
        getTaskAssignableUsers(){
            this.$http.get('/projects/Get_TaskAssignableUsers/' + this.taskId)
            .then((response) => { 
                this.assignableUsers = response.data
            })
            .catch(() => {
            this.$message.error('There has been an error')
            })
        },
        updateTask(){
            this.$http.post('/projects/Update_Task', this.task)
            .then(() => {
                this.$message.success('Task Updated')
                this.getTask()
            })
            .catch(() => {
            this.$message.error('There has been an error')
            })
        }
    }
}
</script>

<style scoped>
.form-label {
    margin-top: 5px;
}
.footer {
  position: fixed;
  bottom: 0;
  margin: 1em;
  margin-left: 6em;
  max-height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column-reverse;
}
</style>