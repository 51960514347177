<template>
  <div class="row" v-if="taskUsersTimeSpent">
      <div class="col-lg-12">
          <vue-good-table
            :columns="userColumns"
            :rows="taskUsersTimeSpent"
          >

          </vue-good-table>
      </div>
      <!-- <div class="col-lg-12"> SWAP TO THESE WHEN COMPLETED DATES ARE IN PLACE. NEED TO UPDATE SP Get_TaskUsersTimeSpent and add calc for percent
            <div class="progress-list gy-3">
                <div v-for="tuts in taskUsersTimeSpent" v-bind:key="tuts.assignedToUserId" class="progress-wrap">
                    <div class="progress-text">
                        <div class="progress-label">{{tuts.assignedToUser}} (<small class="text-muted">{{tuts.calcHour}}</small>)</div>
                    </div>
                    <a-progress :showInfo="false" :percent="tuts.calcHour"/>
                </div>
            </div>
      </div> -->
  </div>
</template>

<script>
export default {
    props: {
        taskId: {
            type: String
        }
    },
    data() {
        return {
            taskUsersTimeSpent: null,
            userColumns: [
                { label: 'User', field: 'assignedToUser', sortable: false },
                { label: 'Time Spent (Hours)', field: 'calcHour', sortable: false },
            ],
        }
    },
    created() {
        this.getUsersTimeSpent()
    },
    methods: {
        getUsersTimeSpent() {
            this.$http.get('/projects/Get_TaskUsersTimeSpent/' + this.taskId)
            .then((response) => { 
                this.taskUsersTimeSpent = response.data
            })
            .catch(() => {
            this.$message.error('There has been an error')
            })
        }
    }
}
</script>

<style>

</style>