<template>
    <div>
        <div style="padding: 10px;">
            <table class="table">
                <thead>
                    <th style="width:2.8%;"></th>
                    <th style="width:66%;">Task Name</th>
                    <th style="width:10%">Assigned To</th>
                    <th style="width:10%">Due Date</th>
                    <th style="width:10%">Tags</th>
                </thead>
            </table>
            <div style="padding-top:10px;" v-for="s in project.projectSections" :key="s.id">
                <div class="col-lg-12" style="padding: 5px;">
                    <h5 class="title" v-if="s.title">{{ s.title }}  
                        <a-dropdown>
                            <a class="btn btn-icon btn-trigger toggle-expand mr-n1"><em class="icon ni ni-more-h"></em></a>
                            <a-menu slot="overlay">
                                <a-menu-item style="color: black;"><em class="icon ni ni-edit"/> Rename</a-menu-item>
                                <a-menu-item style="color: red;"><em class="icon ni ni-cross-circle"/> Delete</a-menu-item>
                            </a-menu>
                        </a-dropdown>
                    </h5>
                </div>
                <div class="col-lg-12">
                    <div>
                        <projectTask @completeTask="completeTask" @taskSelected="taskSelected" v-for="t in s.projectTasks" :key="t.id" :task="t" />
                    </div>
                    <div v-if="!addNewTask.show || addNewTask.sectionId != s.id" v-on:click="addTaskToSection(s.id)" class="taskCell" style="margin-left:19px; font-weight:500; border-right:0px;"> Add Task...</div>
                    <div v-if="addNewTask.show && addNewTask.sectionId == s.id">
                        <projectTask @completeTask="completeTask" @taskSelected="taskSelected" :task="taskTemplate" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script> 
import projectTask from '@/components/projects/list/task.vue'

export default {
    components: {  projectTask },
    props: {
        project: {
            type: Object
        },
        addNewTask: {
            type: Object
        },
        taskTemplate: {
            type: Object
        }
    },
    methods: {
        completeTask(taskId) {
            this.$emit('completeTask',taskId)
        },
        addTaskToSection(sectionId) {
            this.$emit('addTask', sectionId)
        },
        taskSelected (taskId){
            this.$emit('taskSelected', taskId)
        }
    },
    data() {
        return {
            task: {}
        }
    }
}
</script>

<style>

</style>